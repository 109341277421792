<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const getSocialIcon = (url) => {
  if (url.includes('linkedin')) {
    return '#icon-social-linkedin'
  } else if (url.includes('facebook')) {
    return '#icon-social-facebook'
  } else if (url.includes('instagram')) {
    return '#icon-social-instagram'
  } else if (url.includes('twitter')) {
    return '#icon-social-twitter'
  } else if (url.includes('youtube')) {
    return '#icon-social-youtube'
  }

  return ''
}

const inputTrick = ref('')

onMounted(() => {
  inputTrick.value = makeId(4)
})

function makeId(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
</script>

<template>
  <section class="section section--form-contact" id="offices-form">
    <div class="section-wrapper">
      <div class="left-wrapper">
        <div class="country-office" v-for="office in content.section.offices">
          <div class="office-title">
            {{ office.place }}
          </div>
          <div class="office-type">
            {{ office.type }}
          </div>
          <div
            class="office-body"
            v-if="office.body"
            v-html="office.body"></div>
        </div>
        <ul class="share-list" v-if="content.section.social">
          <li class="share-item" v-for="link in content.section.social">
            <a
              :href="link"
              target="_blank"
              class="btn btn--round btn--round-dark sensible"
              v-if="link">
              <svg class="icon icon--btn-round">
                <use :xlink:href="getSocialIcon(link)"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div class="right-wrapper">
        <SharedContactForm />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--form-contact {
  background-color: $color-gray-light;
  padding-bottom: pxrem(50);
  padding-top: pxrem(50);

  @include mq(md) {
    padding-bottom: pxrem(120);
    padding-top: pxrem(120);
  }

  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
      display: flex;
    }
  }

  .left-wrapper {
    @include mq(md) {
      @include make-col(7);
    }
  }

  .country-office {
    border-bottom: 1px solid $color-gray;
    margin-bottom: pxrem(30);
    padding-bottom: pxrem(30);

    &:last-of-type {
      border-bottom: none;
    }
  }

  .office-title {
    @include display-3;
    font-weight: 700;
  }

  .office-type {
    @include display-3;
  }

  .office-body {
    @include paragraph;

    @include mq(md) {
      max-width: 70%;
    }
  }

  .share-list {
    @include list-reset;
    margin-bottom: pxrem(35);

    @include mq(md) {
      margin-bottom: pxrem(50);
    }
  }

  .share-item {
    display: inline-block;
    margin-right: pxrem(8);

    &:last-of-type {
      margin-right: 0;
    }
  }

  .right-wrapper {
    @include mq(md) {
      @include make-col(11);
      @include make-col-offset(2);
    }
  }

  .form-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(30);

    @include mq(md) {
      margin-bottom: pxrem(45);
    }
  }

  .form {
    column-gap: pxrem(15);
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: pxrem(30);

    @include mq(sm) {
      column-gap: pxrem(20);
      row-gap: pxrem(40);
    }
  }

  .form-item {
    grid-column: span 2;

    @include mq(sm) {
      grid-column: span 1;
    }
  }

  .form-item--full {
    grid-column: span 2;
  }

  .form-label {
    @include paragraph;
    display: block;
    margin-bottom: pxrem(10);
  }

  .form-input {
    @include field-reset;
    @include paragraph;
    border: 1px solid $color-gray-blue;
    border-radius: 5px;
    cursor: none;
    padding: pxrem(12) pxrem(35) pxrem(12) pxrem(15);
    transition: all $transition-default;
    user-select: none;
    width: 100%;
  }

  .form-item-actions {
    text-align: center;
  }
}
</style>
