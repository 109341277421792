<script setup>
import {gsap} from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const labelClass = (index) => {
  return `label--${index}`
}

function scrollDiscoverMore(event) {
  const scrollTarget = event.target.dataset.scroll

  gsap.to(window, {duration: 0.6, scrollTo: scrollTarget, ease: 'power2.inOut'})
}
</script>

<template>
  <section class="section section--marquee">
    <div class="marquee" v-for="city in content.section.cities">
      <div
        class="marquee-inner"
        v-for="(index, labelIndex) in Math.max(5, (10 - city.length) * 2)"
        :key="index"
        :class="{cloned: labelIndex !== 0}"
        :aria-hidden="labelIndex !== 0">
        <div class="scrolling-bar-item marquee-item">
          <span class="label" :class="labelClass(labelIndex)">{{ city }}</span>
        </div>
      </div>
    </div>
    <div class="cta-wrapper animate-in-bottom-early animate-out-left">
      <div
        data-scroll="#offices-form"
        class="cta cta--light sensible"
        @click="scrollDiscoverMore">
        {{ $t('contact_offices') }}
        <div class="menu-link-btn btn btn--round-small btn--round-light">
          <svg class="icon icon--btn-round-small icon--rotate-90">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--marquee {
  background-color: $color-blue;
  padding-bottom: pxrem(40);
  padding-top: pxrem(40);

  @include mq(md) {
    padding-bottom: pxrem(60);
    padding-top: pxrem(70);
  }

  .marquee {
    --gap: 1rem;
    border-bottom: 1px solid rgba($color-white, 0.1);
    display: flex;
    gap: var(--gap);
    padding-bottom: pxrem(10);
    padding-top: pxrem(10);
    position: relative;
    overflow: hidden;
    user-select: none;

    @include mq(md) {
      --gap: 2rem;
    }

    &:first-of-type {
      border-top: 1px solid rgba($color-white, 0.1);
    }
    .marquee-inner {
      display: inline-flex;
      flex-shrink: 0;
      gap: var(--gap);
      will-change: transform;
    }

    @keyframes scroll {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(-100% - var(--gap)));
      }
    }

    .marquee-inner {
      animation: scroll 10s linear infinite;
    }

    .label {
      color: $color-white;
      font-size: $font-xxlarge;
      text-transform: uppercase;

      @include mq(md) {
        font-size: $font-xxxlarge;
      }

      @include mq(lg) {
        font-size: $font-semihuge;
      }

      @include mq(xl) {
        font-size: $font-huge;
      }

      &.label--1,
      &.label--4,
      &.label--7,
      &.label--10 {
        opacity: 0.4;
      }

      &.label--2,
      &.label--5,
      &.label--8 {
        opacity: 0.1;
      }
    }
  }

  .cta-wrapper {
    margin-top: pxrem(30);
    text-align: center;

    @include mq(md) {
      margin-top: pxrem(50);
    }
  }
}
</style>
