<script setup>
import {inject} from 'vue'
import contactsInternalNavJSON from 'assets/data/contacts/internalNav.json'

import transitionConfig from '../../helpers/transitionConfig'

const processedDataMenu = inject('processedDataMenu')

definePageMeta({
  pageTransition: transitionConfig,
})

const {locale} = useI18n()
const parser = ParserUtils()

const route = useRoute()

const fileName = getEndpointFromMenuWithSlug(
  processedDataMenu?.value,
  '/contacts/' + route.params.countrySlug
)

// console.log('ENDPOINT FILE JSON: ', fileName)

const config = useRuntimeConfig()

let endpoint = config.public.apiBase + locale.value + '/countries/' + fileName
// console.log('ENDPOINT: ', endpoint)

const {fetchedData} = await useDataStatic(endpoint)

const processedData = computed(() => {
  if (fetchedData.value) {
    return parser.countryPage(fetchedData.value)
  }
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
  // return null
})

const {injectScriptRecaptcha, deleteScriptRecaptcha} = useRecaptcha()

onMounted(() => {
  injectScriptRecaptcha()
  useAnimations()
})

onUnmounted(() => {
  deleteScriptRecaptcha()
})

useHead({
  title: () =>
    processedData.value?.section.title
      ? `${processedData.value?.section.title}`
      : '',
  meta: [
    {
      name: 'description',
      content: () => `${processedData.value?.section.description}`,
    },
  ],
  bodyAttrs: {
    class: 'page page--country',
  },
})

useSeoMeta({
  ogTitle: () => `${processedData.value?.section.seo.title}`,
  description: () => `${processedData.value?.section.seo.description}`,
  ogDescription: () => `${processedData.value?.section.seo.description}`,
  ogImage: () => `${processedData.value?.section.seo.image}`,
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <div>
    <ContactsLandingHero
      v-if="processedData"
      :content="processedData"
      :internalNav="contactsInternalNavJSON[locale]" />
    <div class="scroll-target" id="content"></div>
    <ContactsMarquee v-if="processedData" :content="processedData" />
    <!-- <pre>{{ JSON.stringify(processedData, null, 2) }}</pre> -->
    <SharedMainContent :content="processedData" v-if="processedData" />
    <ContactsForm v-if="processedData" :content="processedData" />
  </div>
</template>

<style lang="scss">
.page--country {
  .section--sectors {
    margin-bottom: pxrem(40);

    @include mq(md) {
      margin-bottom: pxrem(80);
    }
  }
}
</style>
