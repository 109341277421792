<script setup>
import {gsap} from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import {useLocalePath} from 'vue-i18n-routing'

const localePath = useLocalePath()

gsap.registerPlugin(ScrollToPlugin)

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  internalNav: {
    type: Object,
    required: true,
  },
})

// get sectors and resources for internal navigation
const section = reactive(props.content.section)
const selectedSector = ref()

function scrollDiscoverMore(event) {
  const scrollTarget = event.target.dataset.scroll

  gsap.to(window, {duration: 0.6, scrollTo: scrollTarget, ease: 'power2.inOut'})
}

// get route parameters
const route = useRoute()

const generateSlug = SlugifyUtils()
let slug = ''

// selected sector label
if (props.internalNav) {
  props.internalNav.menu_items.forEach((item) => {
    if (route.params.sectorSlug) {
      slug = route.params.sectorSlug
    } else if (route.params.serviceSlug) {
      slug = route.params.serviceSlug
    } else {
      slug = route.path.split('/').pop()
    }

    if (generateSlug(item.label) === slug) {
      selectedSector.value = item.label
    }
  })
}

const heroInternalNavList = ref()
const heroInternalNavlabelMobile = ref()

function toggleInternalNavigationSubmenu(event) {
  heroInternalNavlabelMobile.value.classList.toggle('open')
  heroInternalNavList.value.classList.toggle('open')
  parallaxContainer.value.classList.toggle('hide')
}

// parallax variables
const parallaxContainer = ref()
const parallaxContainerSkyline = ref()

const mouseX = ref(0)
const mouseY = ref(0)
const gyroscopeBeta = ref(0)
const gyroscopeGamma = ref(0)
const dx = ref(0)
const dy = ref(0)
const interpolationFactor = 0.025
const scalingFactorXMouse = 0.05
const scalingFactorYMouse = 0.1
const scalingFactorXGyroscope = 2000
const scalingFactorYGyroscope = 2000

// interpolation function
function lerp(a, b, n) {
  return (1 - n) * a + n * b
}

onMounted(() => {
  const hasNoFinePointer = !window.matchMedia('(pointer:fine)').matches
  if (hasNoFinePointer) {
    if (typeof DeviceOrientationEvent?.requestPermission === 'function') {
      // request permission to use orientation sensor
      console.log('request permission to use orientation sensor')
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            // Permission granted, handle device motion
            window.addEventListener('deviceorientation', handleOrientation)
            requestAnimationFrame(updateMovementGyroscope)
          } else {
            // Permission denied or dismissed by the user
            console.log('Motion sensor permission denied by the user.')
          }
        })
        .catch((error) => {
          // An error occurred while requesting permission
          console.error('Error requesting motion sensor permission:', error)
        })
    } else {
      // For older browsers that do not support the Permissions API
      console.log('Motion sensor permission API is not supported.')
      window.addEventListener('deviceorientation', handleOrientation)
      requestAnimationFrame(updateMovementGyroscope)
    }
  }
  if (!hasNoFinePointer || typeof DeviceOrientationEvent?.requestPermission !== 'function') {
    document.addEventListener('mousemove', handleMouseMove)
    requestAnimationFrame(updateMovement)
  }
})

onUnmounted(() => {
  // parallax
  window.removeEventListener('mousemove', handleMouseMove)
})

// parallax function to handle gyroscope orientation
function handleOrientation(event) {
  // console.log('   - HANDLE ORIENTATION: ', event)
  const normalizedBeta = (Math.round(event.beta) - 90) / 90 // Normalize beta (pitch) value to range -1 to 1
  const normalizedGamma = Math.round(event.gamma) / 90 // Normalize gamma (roll) value to range -1 to 1

  // Update gyroscope values
  gyroscopeBeta.value = normalizedBeta
  gyroscopeGamma.value = normalizedGamma
}

// parallax function to update target movement with gyroscope-based interpolation
function updateMovementGyroscope() {
  if (parallaxContainer.value) {
    const containerRect = parallaxContainer.value.getBoundingClientRect()
    const containerCenterX = containerRect.left + containerRect.width / 2
    const containerCenterY = containerRect.top + containerRect.height / 2

    // Adjust the lerp computation for gyroscope values (-1 to 1)
    dx.value = lerp(
      containerCenterX,
      containerCenterX + gyroscopeGamma.value * scalingFactorXGyroscope,
      interpolationFactor
    )
    dy.value = lerp(
      containerCenterY,
      containerCenterY + gyroscopeBeta.value * scalingFactorYGyroscope,
      interpolationFactor
    )

    const moveX = containerCenterX - dx.value
    const moveY = containerCenterY - dy.value

    parallaxContainer.value.style.setProperty(
      '--parallax-translate-x',
      `${moveX}px`
    )
    parallaxContainer.value.style.setProperty(
      '--parallax-translate-y',
      `${moveY}px`
    )
  }

  if (parallaxContainerSkyline.value) {
    const containerRect = parallaxContainer.value.getBoundingClientRect()
    const containerCenterX = containerRect.left + containerRect.width / 2
    const containerCenterY = containerRect.top + containerRect.height / 2

    // Adjust the lerp computation for gyroscope values (-1 to 1)
    dx.value = lerp(
      containerCenterX,
      containerCenterX + gyroscopeGamma.value * scalingFactorXGyroscope,
      interpolationFactor
    )
    dy.value = lerp(
      containerCenterY,
      containerCenterY + gyroscopeBeta.value * scalingFactorYGyroscope,
      interpolationFactor
    )

    const moveX = containerCenterX - dx.value
    const moveY = containerCenterY - dy.value

    parallaxContainerSkyline.value.style.setProperty(
      '--parallax-skyline-translate-x',
      `${moveX}px`
    )
    parallaxContainerSkyline.value.style.setProperty(
      '--parallax-skyline-translate-y',
      `${moveY}px`
    )
  }

  requestAnimationFrame(updateMovementGyroscope)
}

// parallax function to manage mouse movement
function handleMouseMove(event) {
  mouseX.value = event.clientX
  mouseY.value = event.clientY
}

// parallax function to update target movement (mouse driven) with interpolation
function updateMovement() {
  if (parallaxContainer.value) {
    const containerRect = parallaxContainer.value.getBoundingClientRect()
    const containerCenterX = containerRect.left + containerRect.width / 2
    const containerCenterY = containerRect.top + containerRect.height / 2

    dx.value = lerp(dx.value, mouseX.value, interpolationFactor)
    dy.value = lerp(dy.value, mouseY.value, interpolationFactor)

    const moveX = (containerCenterX - dx.value) * scalingFactorXMouse * 0.1
    const moveY = (containerCenterY - dy.value) * scalingFactorYMouse * 0.1

    const moveSkylineX =
      (containerCenterX - dx.value) * scalingFactorXMouse * 0.25
    const moveSkylineY =
      (containerCenterY - dy.value) * scalingFactorYMouse * 0.25

    parallaxContainer.value.style.setProperty(
      '--parallax-translate-x',
      `${moveX}px`
    )
    parallaxContainer.value.style.setProperty(
      '--parallax-translate-y',
      `${moveY}px`
    )

    parallaxContainerSkyline.value.style.setProperty(
      '--parallax-skyline-translate-x',
      `${moveSkylineX}px`
    )
    parallaxContainerSkyline.value.style.setProperty(
      '--parallax-skyline-translate-y',
      `${moveSkylineY}px`
    )
  }

  requestAnimationFrame(updateMovement)
}
</script>

<template>
  <section class="hero hero--landing-country">
    <div class="hero-bgs hero-bgs--back">
      <picture
        class="hero-image hero-image--back animate-in-fade animate-out-fade">
        <source
          v-for="asset in filterPictureSourceTags(section.assets_back)"
          :srcset="asset.src"
          :type="asset.format"
          :media="asset.media" />
        <img
          v-for="asset in filterPictureImageTag(section.assets_back)"
          :srcset="asset.src"
          :alt="section.title + ' - Pininfarina'" />
      </picture>
    </div>
    <h1
      class="hero-title parallax-container animate-in-fade animate-out-fade"
      ref="parallaxContainer">
      {{ props.content.section.title }}
    </h1>
    <div class="hero-bgs hero-bgs--front animate-in-fade animate-out-fade">
      <picture
        class="hero-image hero-image--front parallax-container-skyline"
        ref="parallaxContainerSkyline">
        <source
          v-for="asset in filterPictureSourceTags(section.assets_front)"
          :srcset="asset.src"
          :type="asset.format"
          :media="asset.media" />
        <img
          v-for="asset in filterPictureImageTag(section.assets_front)"
          :srcset="asset.src"
          :alt="section.title + ' - Pininfarina'" />
      </picture>
    </div>
    <div class="hero-layer" v-if="section.assets"></div>
    <div class="hero-content">
      <div
        class="hero-content-nav animate-in-stagger-bottom animate-out-right"
        v-if="props.internalNav">
        <nav class="hero-internal-nav" v-if="props.internalNav">
          <div class="hero-internal-nav-label animate-in-bottom">
            {{ props.internalNav.label }}
          </div>
          <div
            class="hero-internal-nav-label-mobile"
            ref="heroInternalNavlabelMobile"
            @click="toggleInternalNavigationSubmenu">
            {{ selectedSector }}
            <svg class="icon icon--btn-round-small icon--white-stroke">
              <use xlink:href="#icon-chevron"></use>
            </svg>
          </div>
          <ul class="hero-internal-nav-list" ref="heroInternalNavList">
            <li
              class="hero-internal-nav-item animate-stagger-item"
              v-for="(item, index) in props.internalNav.menu_items">
              <NuxtLink
                :to="localePath(item.path)"
                class="hero-internal-nav-link sensible"
                :class="{
                  active: item.path.split('/').pop() === slug,
                }"
                >{{ item.label }}</NuxtLink
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="hero-content-bottom">
        <div
          data-scroll="#content"
          class="cta cta--light sensible animate-in-fade-early animate-out-left"
          @click="scrollDiscoverMore">
          {{ $t('discover_more') }}
          <div class="menu-link-btn btn btn--round-small btn--round-light">
            <svg class="icon icon--btn-round-small icon--rotate-90">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.parallax-container {
  --parallax-translate-x: 0;
  --parallax-translate-y: 0;
  transform: translate(
    var(--parallax-translate-x),
    var(--parallax-translate-y)
  );
}

.parallax-container-skyline {
  --parallax-skyline-translate-x: 0;
  --parallax-skyline-translate-y: 0;
  transform: translate(
    var(--parallax-skyline-translate-x),
    var(--parallax-skyline-translate-y)
  );
}

.hero--landing-country {
  background-color: $color-blue;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: pxrem(60);
  position: relative;
  z-index: 0;

  &::before {
    background: linear-gradient(0deg, transparent 0%, $color-blue 100%);
    content: '';
    display: block;
    height: 12vh;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &::after {
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba($color-blue, 0.8) 50%,
      $color-blue 100%
    );
    bottom: 0;
    content: '';
    display: block;
    height: 15vh;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .hero-image {
    bottom: -5vh;
    left: -5vw;
    object-fit: cover;
    position: absolute;
    right: -5vw;
    top: -5vh;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &.hero-image--front {
      top: 5vh;

      @include mq(xl) {
        top: 20vh;
      }

      @include mq(xxl) {
        top: 25vh;
      }

      @include mq(full) {
        top: 5vh;
      }
    }
  }

  .hero-title {
    color: $color-white;
    font-family: $font-secondary;
    font-size: $font-xxxlarge;
    font-weight: 400;
    margin-bottom: pxrem(20);
    position: absolute;
    text-align: center;
    top: 20vh;
    transition: opacity $transition-default;
    user-select: none;
    width: 100%;

    &.hide {
      opacity: 0 !important;
    }

    @include mq(md) {
      font-size: $font-semihuge;
      margin-bottom: 0;
      top: 20vh;

      &.hide {
        opacity: 1;
      }
    }

    @include mq(lg) {
      font-size: $font-huge;
      top: 18vh;
    }

    @include mq(xl) {
      font-size: $font-xhuge;
      top: 10vh;
    }

    @include mq(xxl) {
      font-size: $font-xhuge;
      top: 10vh;
    }

    @include mq(full) {
      font-size: $font-xxhuge;
      top: 0vh;
    }
  }

  .hero-layer {
    background-color: rgba(darken($color-blue, 5%), 0.7);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .hero-content {
    @include make-col-ready();
    display: flex;
    flex-direction: column;
    padding-top: pxrem(120);
    position: relative;
    z-index: 2;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      padding-top: pxrem(145);
    }
  }

  .hero-content-nav {
    border-bottom: 1px solid $color-gray-blue;
    margin-bottom: pxrem(35);
    padding-bottom: pxrem(20);

    @include mq(md) {
      margin-bottom: pxrem(60);
    }
  }

  .hero-internal-nav {
    @include mq(md) {
      display: flex;
    }

    .hero-internal-nav-label {
      display: none;

      @include mq(md) {
        @include make-col(4);
        @include paragraph($color-white);
        display: block;
      }
    }

    .hero-internal-nav-label-mobile {
      @include label($color-white);
      display: flex;
      justify-content: space-between;

      svg {
        margin-left: pxrem(10);
        transition: transform $transition-default;
      }

      @include mq(md) {
        display: none;
      }

      &.open {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    .hero-internal-nav-list {
      @include list-reset;
      max-height: 0;
      overflow: hidden;

      transition: max-height $transition-default,
        padding-top $transition-default;
      will-change: max-height;

      @include mq(md) {
        @include make-col(17);
        @include make-col-offset(1);
        max-height: 100%;
        padding-top: 0;
      }

      &.open {
        // background-image: linear-gradient(
        //   0deg,
        //   rgba($color-blue, 0.9) 50%,
        //   rgba(255, 255, 255, 0) 100%
        // );
        max-height: 1000px;
        padding-top: pxrem(10);
      }
    }

    .hero-internal-nav-item {
      @include mq(md) {
        display: inline-block;
        margin-right: pxrem(15);
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .hero-internal-nav-link {
      @include paragraph($color-white);
      display: block;
      padding-bottom: pxrem(4);
      padding-top: pxrem(4);
      text-decoration: none;
      transition: opacity $transition-default;

      &.active {
        display: none;
      }

      @include mq(md) {
        opacity: 0.3;
        padding-bottom: 0;
        padding-top: 0;

        &:hover,
        &.active {
          display: block;
          opacity: 1;
        }
      }
    }
  }

  .hero-content-top {
    @include mq(md) {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      margin-top: -30vh;
    }
  }

  .hero-content-bottom {
    margin-top: auto;
  }
}
</style>
